import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import {
  generateBlogPostingSchema,
  generateBreadcrumbsSchema,
  generatePersonIrshadSchema,
  generatePersonKanchanSchema,
  generatePersonRohanSchema,
} from '../../lib/generate-schema'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import getBreadcrumbs from '../getBreadcrumbs'
import './Template.scss'
import { AUTHORS } from '../../components/internal-links'
import { DISPLAY_TAG_IDENTIFIER } from '../../components/Blogs/constants'
import { modifyHtmlBeforeRendering } from '../../utils/modifyATag'
import { avoidDisplayingAuthorsTags } from '../../utils/common-tags'

const AuthorsBlog: React.FC = (context: any) => {
  const { data, pageContext } = context
  const post = data.currPost
  post.html = modifyHtmlBeforeRendering(post.html)

  // if tag does not exist or it is not a part of that author
  const relatedPost = data.likePosts.edges.filter((el: any) => {
    return (
      el.node.primary_tag?.slug &&
      !avoidDisplayingAuthorsTags.includes(el.node.primary_tag?.slug)
    )
  })

  const { prefix, oneLink } = pageContext

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(prefix, post)

  const [loaded, setLoaded] = useState(false)

  const [authorBio, setAuthorBio] = useState<any | null>([])
  const [authorViews, setAuthorViews] = useState<any | null>([])
  const [authorLocation, setAuthorLocation] = useState<any | null>([])
  const [authorFacebook, setAuthorFacebook] = useState<any | null>([])
  const [authorTwitter, setAuthorTwitter] = useState<any | null>([])
  const [authorInstagram, setAuthorInstagram] = useState<any | null>([])

  useEffect(() => {
    const { html } = post

    const tableContent = document.createElement('div')
    tableContent.innerHTML = `${html}`

    const table = tableContent?.querySelectorAll('table')[0]

    if (table) {
      // setting content
      const rows = table.getElementsByTagName('tr')
      const content = []
      for (let i = 1; i < rows.length; i += 1) {
        const tds = rows[i].getElementsByTagName('td')
        content.push({
          id: i,
          type: tds[0].textContent,
          value: i === 1 ? tds[1].innerHTML : tds[1].textContent,
        })
      }
      setAuthorBio(
        content.filter(item => item.type === 'About').map(item => item.value)[0]
      )
      setAuthorViews(
        content
          .filter(item => item.type === 'Total Post Views')
          .map(item => item.value)[0]
      )
      setAuthorLocation(
        content
          .filter(item => item.type === 'Location')
          .map(item => item.value)[0]
      )
      setAuthorFacebook(
        content
          .filter(item => item.type === 'Facebook')
          .map(item => item.value)[0]
      )
      setAuthorTwitter(
        content
          .filter(item => item.type === 'Twitter')
          .map(item => item.value)[0]
      )
      setAuthorInstagram(
        content
          .filter(item => item.type === 'Instagram')
          .map(item => item.value)[0]
      )
    }

    setLoaded(true)
  }, [post, loaded])

  const findPrefix = (primaryTag: string) => {
    switch (primaryTag) {
      case 'learn-chess':
        return '/casual/chess/learn/'
      case 'learn-cricket':
        return '/casual/learn-cricket/'
      case 'learn-rummy':
        return '/cards/rummy/learn/'
      case 'learn-carrom':
        return '/casual/carrom/learn/'
      case 'learn-pool':
        return '/casual/8-ball-pool/learn/'
      case 'poker-hands':
        return '/cards/poker/hands/'
      case 'poker-strategy':
        return '/cards/poker/strategy/'
      case 'poker-books':
        return '/cards/poker/books/'
      case 'poker-terms':
        return '/cards/poker/terms/'
      case 'hash-gambling':
        return '/gambling/'
      case 'hash-earn_money':
        return '/earn-money/'
      case 'hash-competitor_level2':
        return '/real-money-gaming-apps/'
      case 'learn-poker-2':
        return '/poker/learn/'
      default:
        return '/poker/learn/'
    }
  }

  const getPersonSchema = (author?: any) => {
    switch (author) {
      case 'Rohan Mathwan':
        return generatePersonRohanSchema()
      case 'Kanchan Sharma':
        return generatePersonKanchanSchema()
      case 'Irshad Ahmed Ansari':
        return generatePersonIrshadSchema()

      default:
        return null
    }
  }

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={oneLink}>
      <SEO
        blogPostingSchema={generateBlogPostingSchema({
          articleBody: post.html,
          author: post.primary_author.name,
          createdAt: post.created_at,
          description: post.meta_description,
          image: post.feature_image,
          modifiedAt: post.updated_at,
          publishedAt: post.published_at,
          title: `${
            post.meta_title || post.primary_author.name
          } | Mega Authors}`,
          url: `${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`,
        })}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        personSchema={getPersonSchema(post.primary_author.name)}
        description={post.meta_description}
        title={post.meta_title || `${post.primary_author.name} | Mega Authors`}
        url={`${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`}
      />
      <article id="Post" className="author-blog-post">
        <Container>
          <div className="author-content-wrap">
            <div className="author-top">About {post.primary_author.name}</div>

            <div className="author-card">
              <div className="author-card-image">
                <img src={post.feature_image} alt="img" />
              </div>
              <div className="author-card-texts">
                <h1>{post.primary_author.name}</h1>
                {authorBio && (
                  <div
                    className="author-desc"
                    dangerouslySetInnerHTML={{ __html: authorBio }}
                  />
                )}
                {authorLocation && (
                  <div className="author-location">
                    <GatsbyImage
                      image={data.iconLocation.childImageSharp.gatsbyImageData}
                      alt="icon"
                      imgStyle={{ objectFit: 'contain' }}
                    />
                    <span>{authorLocation}</span>
                  </div>
                )}
                <div className="author-social">
                  {authorFacebook && (
                    <a href={authorFacebook}>
                      <GatsbyImage
                        image={data.iconFb.childImageSharp.gatsbyImageData}
                        alt="icon"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </a>
                  )}
                  {authorTwitter && (
                    <a href={authorTwitter}>
                      <GatsbyImage
                        image={data.iconTwitter.childImageSharp.gatsbyImageData}
                        alt="icon"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </a>
                  )}
                  {authorInstagram && (
                    <a href={authorInstagram}>
                      <GatsbyImage
                        image={data.iconInsta.childImageSharp.gatsbyImageData}
                        alt="icon"
                        imgStyle={{ objectFit: 'contain' }}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="author-views-rating">
              <div className="author-views-sec">
                <div className="author-total-post">
                  <span className="sec-head">{data.totalPosts.totalCount}</span>
                  Articles <br />
                  Published
                </div>

                {authorViews && (
                  <div className="author-total-views">
                    <span className="sec-head">{authorViews}</span>
                    People have read <br />
                    his article
                  </div>
                )}
              </div>
            </div>

            <div className="author-recent-articles">
              <h4>Recent Articles</h4>
              <div className="recent-articles-wrap">
                {relatedPost.length ? (
                  relatedPost.map((edge: any) => {
                    return (
                      <div key={edge.node.slug} className="recent-article-item">
                        {edge.node.feature_image && (
                          <div className="recent-article-image">
                            <img
                              width={233}
                              height={156}
                              src={edge.node.feature_image}
                              alt={edge.node.title}
                            />
                          </div>
                        )}
                        <div className="recent-article-content">
                          <div className="recent-article-meta">
                            Published {edge.node.published_at} &middot;{' '}
                            {edge.node.reading_time} Min Read
                          </div>
                          <h5>
                            <a
                              href={`${
                                edge.node.primary_tag
                                  ? findPrefix(edge.node.primary_tag.slug)
                                  : findPrefix(edge.node.tags[0].slug)
                              }${edge.node.slug}/`}
                            >
                              {edge.node.title}
                            </a>
                          </h5>
                          <div className="recent-articles-tags">
                            {edge.node.tags
                              .filter(
                                (t: { name: string }) =>
                                  !t.name.startsWith(DISPLAY_TAG_IDENTIFIER) &&
                                  !t.name.startsWith('#')
                              )
                              .map((tag: { slug: string; name: string }) => {
                                return (
                                  <a href={findPrefix(tag.slug)} key={tag.slug}>
                                    {tag.name}
                                  </a>
                                )
                              })}
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="no-posts">No Recent Articles Found</div>
                )}
              </div>

              <div className="recent-articles-bottom">
                <Link to={AUTHORS} className="recent-bottom-btn">
                  See Related Bloggers
                </Link>
                {relatedPost.length
                  ? relatedPost[0].node.tags
                      .filter(
                        (t: { name: string }) =>
                          !t.name.startsWith(DISPLAY_TAG_IDENTIFIER) &&
                          !t.name.startsWith('#')
                      )
                      .map((tag: { slug: string; name: string }) => {
                        return (
                          <a
                            href={findPrefix(tag.slug)}
                            key={tag.slug}
                            className="recent-bottom-btn btn-colred"
                          >
                            More Articles +
                          </a>
                        )
                      })
                  : null}
              </div>
            </div>
          </div>
        </Container>
      </article>
    </Layout>
  )
}

export default AuthorsBlog

export const postQuery = graphql`
  query ($slug: String!, $primary_author_slug: String!) {
    currPost: ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      excerpt
      meta_title
      meta_description
      published_at
      created_at
      reading_time
      updated_at(formatString: "MMMM DD, YYYY")
      custom_template
      tags {
        slug
      }
      primary_author {
        name
        slug
      }
    }
    totalPosts: allGhostPost(
      filter: {
        slug: { ne: $slug }
        authors: { elemMatch: { slug: { eq: $primary_author_slug } } }
      }
    ) {
      totalCount
    }
    likePosts: allGhostPost(
      limit: 4
      filter: {
        slug: { ne: $slug }
        primary_author: { slug: { eq: $primary_author_slug } }
      }
      sort: { fields: published_at, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          published_at(fromNow: true)
          updated_at
          reading_time
          excerpt
          feature_image
          tags {
            slug
            name
          }
          primary_tag {
            slug
          }
        }
      }
    }
    iconFb: file(relativePath: { eq: "fb-icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 16, layout: FIXED)
      }
    }
    iconTwitter: file(relativePath: { eq: "twitter-icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 16, layout: FIXED)
      }
    }
    iconInsta: file(relativePath: { eq: "insta-icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 16, layout: FIXED)
      }
    }
    iconLocation: file(relativePath: { eq: "location-icon.png" }) {
      childImageSharp {
        gatsbyImageData(width: 8, layout: FIXED)
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
