export const DISPLAY_TAG_IDENTIFIER = 'display/'

export const ALL_BLOGS_TAG_IDENTIFIER = 'All'

export const POKER_BLOGS_TAG_IDENTIFIER = 'learn-poker'

export const RUMMY_BLOGS_TAG_IDENTIFIER = 'Learn Rummy'

export const CARROM_BLOGS_TAG_IDENTIFIER = 'Learn Carrom'

export const POOL_BLOGS_TAG_IDENTIFIER = 'Learn Pool'

export const CHESS_BLOGS_TAG_IDENTIFIER = 'Learn Chess'

export const COMPETITOR_BLOGS_TAG_IDENTIFIER = '#competitor_website'

export const EARN_MONEY_BLOGS_TAG_IDENTIFIER = '#earn_money'

export const GAMBLING_BLOGS_TAG_IDENTIFIER = '#gambling'

export const LISTINGS_BLOGS_TAG_IDENTIFIER = '#listings'

export const REAL_MONEY_GAMES_BLOGS_TAG_IDENTIFIER = '#competitor_level2'

export const AUTHOR_BLOGS_TAG_IDENTIFIER = '#getmega_author'

export const CRICKET_BLOGS_TAG_IDENTIFIER = 'Learn Cricket'

export const NEWS_TAG_IDENTIFIER = 'News'
